/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const PinNet = createSvgIcon(
    <>
        <path
            d="M11.25 5.83301C11.25 9.91634 6 13.4163 6 13.4163C6 13.4163 0.75 9.91634 0.75 5.83301C0.75 4.44062 1.30312 3.10526 2.28769 2.1207C3.27226 1.13613 4.60761 0.583008 6 0.583008C7.39239 0.583008 8.72774 1.13613 9.71231 2.1207C10.6969 3.10526 11.25 4.44062 11.25 5.83301Z"
            stroke="#666766"
            strokeWidth="1.3125"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6 7.58298C6.9665 7.58298 7.75 6.79948 7.75 5.83298C7.75 4.86648 6.9665 4.08298 6 4.08298C5.0335 4.08298 4.25 4.86648 4.25 5.83298C4.25 6.79948 5.0335 7.58298 6 7.58298Z"
            stroke="#666766"
            strokeWidth="1.3125"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>,
    'PinNet'
)

export default PinNet
