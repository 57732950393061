/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { processAuthors } from '../../../../helpers/utils/processAuthors'
import { HtmlContent } from '../../../HtmlContent'
import { Image, imageRatioVariants } from '../../../Image'
import { Meta } from '../../../Meta'
import { netTitleVariants, Title } from '../../../Title'
import { Inpicture } from '../Inpicture/Inpicture.component'
import styles from './Head.net.style'

const HeadStyled = styled.div(props => ({ ...styles(props) }))

function Head({
    className,
    image,
    imageUpdatedAt,
    imageVariation,
    title,
    subtitle,
    lead,
    variant,
    author,
    authors = [],
    showAuthorInitials,
    date,
    photoAuthor,
    categoryColor,
    columnist,
    noHeroImage,
    authorSlug,
    isHunk,
    isZodiac,
    inPictureAdConfig,
    ...rest
}) {
    const isAmp = useAmp()
    const isColumnist = !!columnist

    const getImageWidth = () => {
        if (isAmp) {
            return variant === 'gamma' ? 800 : 1200
        }

        if (!image?.id) {
            return 705
        }

        if (variant === 'alpha' && !isColumnist) {
            return undefined
        }

        return 705
    }

    const getImageHeight = () => {
        if (isAmp) {
            return variant === 'gamma' ? 'auto' : 800
        }

        if (!image?.id) {
            return 400
        }

        if (variant === 'alpha' && !isColumnist) {
            return undefined
        }

        return 400
    }

    const getImageVariation = () => {
        switch (variant) {
            case 'alpha':
                return imageRatioVariants.CUSTOM_WIDE_SCREEN

            default:
                return imageRatioVariants.CUSTOM_ORIGINAL
        }
    }

    return (
        <HeadStyled variant={variant} isColumnist={isColumnist} hasImage={image} isHunk={isHunk} {...rest}>
            <Title
                className="articleHead_title"
                id="article_head_title"
                title={title}
                subtitle={subtitle}
                variant={variant === 'gamma' ? netTitleVariants.BETA : netTitleVariants.ALPHA}
                isZodiac={isZodiac}
                categoryColor={categoryColor}
            />
            {['alpha', 'gamma'].includes(variant) && (
                <>
                    {!noHeroImage && image && (
                        <div className="articleHead_inpicture_wrap">
                            <Image
                                className="articleHead_image"
                                classNameProgressive="articleHead_imageProgressive"
                                id="article_head_image"
                                image={image}
                                updatedAt={imageUpdatedAt}
                                variation={getImageVariation()}
                                alt={image?.title}
                                width={getImageWidth()}
                                height={getImageHeight()}
                                preload
                                lazyLoad={false}
                                useLargestInstance
                                isHeadImage
                            />

                            <Inpicture key={image?.id} inPictureAdConfig={inPictureAdConfig} />
                        </div>
                    )}
                    {['alpha'].includes(variant) && !isZodiac && (
                        <HtmlContent data={lead} className="articleHead_lead" id="article_head_lead" />
                    )}
                    {columnist && !isZodiac && <div className="articleHead_columnist">{columnist}</div>}
                    {/* {columnist} */}
                    {!isZodiac && (
                        <Meta
                            className="articleHead_meta"
                            id="article_head_meta"
                            authorSlug={!columnist && authorSlug}
                            author={!columnist && author}
                            authors={!columnist && processAuthors(authors)}
                            showAuthorInitials={showAuthorInitials}
                            photoAuthor={photoAuthor}
                            date={date}
                        />
                    )}
                </>
            )}
        </HeadStyled>
    )
}

const articleHeadVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma'
})

Head.propTypes = {
    className: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    imageVariation: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    lead: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(articleHeadVariants)]),
    author: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    authors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired
        }),
        PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired
            })
        )
    ]),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    photoAuthor: PropTypes.string,
    imageUpdatedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    categoryColor: PropTypes.string,
    columnist: PropTypes.node,
    noHeroImage: PropTypes.bool,
    authorSlug: PropTypes.string,
    isHunk: PropTypes.bool,
    isZodiac: PropTypes.bool,
    inPictureAdConfig: PropTypes.shape({
        id: PropTypes.string,
        size: PropTypes.string,
        targeting: PropTypes.shape({
            pos: PropTypes.string
        })
    }),
    showAuthorInitials: PropTypes.bool
}

Head.defaultProps = {
    className: undefined,
    image: undefined,
    imageVariation: undefined,
    title: undefined,
    subtitle: undefined,
    lead: undefined,
    variant: netTitleVariants.ALPHA,
    author: undefined,
    authors: [],
    date: undefined,
    photoAuthor: undefined,
    imageUpdatedAt: undefined,
    categoryColor: undefined,
    columnist: undefined,
    noHeroImage: false,
    authorSlug: undefined,
    isHunk: undefined,
    isZodiac: undefined,
    inPictureAdConfig: undefined,
    showAuthorInitials: false
}

export { articleHeadVariants }
export default withErrorBoundary(Head, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Head]: ', error, componentStack)
    }
})
