/* eslint-disable max-len */
import React from 'react'

import { createSvgIcon } from '../../utils'

const TiktokNet = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8186 0.980475C7.58617 0.96875 8.34785 0.974604 9.10953 0.96875C9.15641 1.86523 9.47867 2.77928 10.1349 3.41207C10.7911 4.06246 11.7168 4.36129 12.6191 4.46089V6.8222C11.7754 6.79289 10.9258 6.61711 10.1583 6.25384C9.82432 6.10149 9.51381 5.90813 9.20912 5.70892C9.20327 7.41983 9.215 9.13076 9.19742 10.8358C9.15054 11.6561 8.88101 12.4706 8.40644 13.1444C7.63889 14.2694 6.30887 15.0018 4.94369 15.0252C4.10584 15.0721 3.26798 14.8436 2.55317 14.4217C1.36963 13.7245 0.537624 12.4471 0.414595 11.076C0.402871 10.7831 0.397017 10.4901 0.408724 10.203C0.514193 9.08974 1.06495 8.02334 1.92039 7.29679C2.89299 6.45305 4.2523 6.04876 5.52373 6.289C5.53545 7.15618 5.50032 8.02334 5.50032 8.89053C4.92024 8.70302 4.2406 8.75575 3.73085 9.10732C3.36173 9.34756 3.08049 9.71668 2.93401 10.1327C2.81097 10.4315 2.84612 10.7596 2.85198 11.076C2.9926 12.037 3.91834 12.8456 4.90268 12.7577C5.5589 12.7518 6.18583 12.3709 6.52567 11.8143C6.63697 11.621 6.76002 11.4217 6.76587 11.1932C6.82448 10.1444 6.80103 9.10146 6.8069 8.05265C6.81275 5.69134 6.80103 3.33591 6.8186 0.980475Z"
        fill="white"
    />,
    'TiktokNet'
)

export default TiktokNet
